import { post } from '@/utils/request'

//家庭管理列表
export function postFamilyList(data) {
    return post('admin/family/list', data)
}
//家庭成员列表
export function postMemberList(data) {
    return post('admin/family/member/list', data)
}
//家庭消费列表
export function posthistoryList(data) {
    return post('admin/family/purchase/history', data)
}
//家庭消费列表
export function postFamilyAssessor(data) {
    return post('admin/family/assessor', data)
}
